#custom-header {
    background: #0b2b33;
    padding: 0 10px 0 10px;
}

.custom-header-mobile {
    background: #0b2b33;
    padding: 0 10px 0 10px;
    position: sticky;
    top: 0;
    z-index: 9999;
    width: 100%;
    color: #fff;
}

.ant-layout {
    background: #e7e9ed;
}

.ant-layout-header {
    height: 48px;
    line-height: 48px;
}

.header-actions {
    cursor: pointer;
}

.ant-popover-inner {
    border-radius: 8px;
}

.login-logo {
    width: 230px;
}

.text-white {
    color: #fff;
}

.color-primary {
    color: #0b2b33;
}

.color-red {
    color: red;
}

.color-blue {
    color: rgb(9, 1, 235);
}

.delete-icon {
    color: #d26364 !important;
    cursor: pointer;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #6c737336;
}

.logo img {
    height: 50px !important;
}

.logo a, .logo a:hover {
    font-weight: bold;
    color: #fff;
    text-decoration: none;
}

.header-icons {
    color: #fff;
    font-size: 20px;
}

.ant-layout-sider-trigger {
    background: #0b2b3347 !important;
    font-weight: bolder;
    font-size: 20px;
}

.content-wrapper {
    min-height: 90vh;
}

.login-card {
    margin-top: 25px;
    width: 30%;
}

.ios-badge {
    display: inline-block;
    overflow: hidden;
    background: url(https://linkmaker.itunes.apple.com/vi-vn/badge-lrg.svg?releaseDate=2019-12-02&kind=iossoftware&bubble=ios_apps) no-repeat;
    width: 135px;
    height: 40px;
    margin-left: 10px;
}

.android-badge img {
    height: 60px;
}

.ql-editor {
    min-height: 250px;
}

.sidebar-icon {
    font-size: 22px !important;
}

.sidebar-icon-font-awesome {
    font-size: 18px !important;
}

.ecom-menu .ant-menu-item,
.ecom-menu .ant-menu-submenu-title {
    padding-left: 14px !important;
}

.drawer-logo-mobile {
    height: 48px;
}

.ecom-menu .ant-menu-item-only-child {
    padding-left: 46px !important;
}

.item-action-btn {
    cursor: pointer;
    color: #0a4e8f
}

.item-action-btn:hover {
    color: #188fff
}

.item-action-btn-remove {
    color: rgb(158, 39, 39);
    cursor: pointer;

}

.item-action-btn-remove:hover {
    color: red;
}

.site-page-header {
    padding-left: 0 !important
}

.homepage-card {
    width: 100% !important;
}

.homepage-card .ant-card-cover img {
    width: 100%;
    height: 200px;
    object-fit: cover
}

.homepage-card .ant-card-body {
    text-align: center
}

.notification-list {
    max-height: 400px;
    overflow-y: auto;
}

.custom-col {
    width: 100% !important;
}

.custom-radio {
    display: block;
    height: 30px;
    line-height: 30px
}

#request-menu {
    margin-left: -24px !important;
    margin-right: -24px !important;
    height: max-content;
    display: flex;
    justify-content: center;
    background-color: #575f63;
}

#request-menu .ant-menu-item {
    text-align: center !important;
    min-width: 130px !important;
    padding-top: 12px !important;
    background-color: #575f63;
}

#request-menu .ant-menu-item-selected {
    background-color: #fff !important
}

#request-menu .ant-menu-item-selected span {
    color: #e47025 !important;
}

#request-menu .ant-menu-item span {
    display: block !important;
    word-break: break-all;
    font-size: 12px;
    line-height: 36px;
}

#request-menu .ant-menu-item .anticon {
    font-size: 40px !important;
    margin-right: 0 !important;
}

/* .ant-form-item-label {
    font-weight: bolder
} */

.am-notice-bar {
    margin-left: -24px !important;
    margin-right: -24px !important;
}

.ant-layout-footer {
    padding-left: 0 !important
}

.module-cards {
    min-height: 140px;
    border-radius: 25px;
    border: none !important;
    color: #fff;
    background-color: #575f63;
}

.module-cards .ant-card-body {
    padding: 15px 20px 20px 25px !important;
}

.module-cards .anticon {
    font-size: 40px !important;
}

.module-cards .view-more-icon {
    font-size: initial !important;
}

.module-cards:hover {
    background-color: #e47025 !important;
}

.text-none {
    text-decoration: none !important
}

.product-card {
    border-radius: 15px;
}

.product-card .ant-card-body {
    padding-left: 15px !important;
    padding-right: 15px !important;
}

.product-img {
    height: 150px;
    width: 100%;
    object-fit: cover;
    border-radius: 15px 15px 0px 0px !important;
}

.price {
    font-weight: 700;
    color: #e74c3c;
}

.old-price {
    text-decoration: line-through;
    font-size: 12px;
}

.product-img-detail {
    width: 100%;
    height: 500px;
    object-fit: cover;
}

.slide .product-img-detail {
    width: 100% !important;
    height: 500px !important;
    object-fit: contain !important
}

.thumb .product-img-detail {
    height: 80px !important;
    object-fit: cover !important;
}

.carousel .slide {
    background-color: #e6eaf0 !important;
}

.thumbs.animated {
    padding: 0 !important;
    text-align: center;
}

.product-statistic-card-cash-book-list {
    width: 14%;
    border-radius: 0 !important;
}

.product-statistic-card-cash-book-list .ant-card-body {
    padding: 5px !important;
}

.product-statistic-card-cash-book-list .ant-statistic-title {
    font-size: 14px;
}

.product-statistic-card-cash-book-list .ant-statistic-content {
    font-size: 16px;
}


.cash-receipt-statistic {
    width: 25%;
    border-radius: 0 !important;
}

.cash-receipt-statistic .ant-card-body {
    padding: 5px !important;
}

.cash-receipt-statistic .ant-statistic-title {
    font-size: 14px;
}

.cash-receipt-statistic .ant-statistic-content {
    font-size: 16px;
}

.customer-services-list-statistic {
    width: 16.5%;
    border-radius: 0 !important;
}

.customer-services-list-statistic .ant-card-body {
    padding: 5px !important;
}

.customer-services-list-statistic .ant-statistic-title {
    font-size: 14px;
}

.customer-services-list-statistic .ant-statistic-content {
    font-size: 16px;
}

.product-statistic-card {
    width: 16%;
}

.product-statistic-card .ant-card-body {
    padding: 10px 15px !important;
}

.product-statistic-card2 {
    width: 14%;
}

.product-statistic-card2 .ant-card-body {
    padding: 10px 15px !important;
}

.product-statistic-card2 .ant-statistic-title {
    font-size: 12px;
}

.product-statistic-card2 .ant-statistic-content {
    font-size: 15px;
}

.product-statistic-card3 {
    width: 25%;
}

.product-statistic-card3 .ant-card-body {
    padding: 10px 15px !important;
}

.product-statistic-card4 {
    width: 20%;
}

.product-statistic-card4 .ant-card-body {
    padding: 10px 15px !important;
}

.img-modal {
    width: unset !important;
    max-width: 90%;
    min-width: 520px;
}

.table-button {
    margin-right: .6rem !important;
    margin-bottom: .5rem !important;
}

@media only screen and (max-width: 768px) {
    .login-card {
        margin-top: 0px !important;
        width: 100% !important;
    }

    .homepage-card {
        margin-top: 25px
    }

    #request-menu .ant-menu-item {
        text-align: center !important;
        max-width: 80px !important;
        padding-top: 20px !important;
        padding-left: 5px !important;
        padding-right: 5px !important;
    }

    .product-default-img {
        display: none !important;
    }

    .slide .product-img-detail {
        height: 200px !important;
        width: 100% !important;
        object-fit: cover;
    }

    .table-filter, .table-button {
        margin-bottom: .5rem !important;
        width: 100% !important;
        margin-right: 0 !important;
    }

    .product-statistic-card {
        width: 100%;
        /* margin-bottom: 1em; */
    }

    .product-statistic-card2 {
        width: 100%;
        /* margin-bottom: 1em; */
    }

    .product-statistic-card3 {
        width: 100%;
        /* margin-bottom: 1em; */
    }

    .product-statistic-card4 {
        width: 100%;
        /* margin-bottom: 1em; */
    }

    .product-statistic-card-cash-book-list {
        width: 100%;
        /* margin-bottom: 1em; */
    }

    .cash-receipt-statistic {
        width: 100%;
    }

    .customer-services-list-statistic {
        width: 100%;
    }

    .fix-buttom-mobile, .training-img img {
        width: 100%;
    }

    .fix-width-card-idea .ant-card-extra {
        width: 100%;
    }

    .ant-list-vertical .ant-list-item-action>li {
        padding: 8px 16px;
        width: 100%;
    }

    .row-group-input .ant-col {
        margin-bottom: 15px;
    }

    .row-group-input .ant-col:last-child {
        margin-bottom: 0;
    }

    .form-item-label-right .ant-legacy-form-item-label {
        text-align: left !important;
    }

    .customer-data-statistic {
        width: 100% !important;
    }

}



.filter-icon {
    float: right;
    margin-left: 10px;
    font-size: 20px;
    cursor: pointer;
    line-height: 32px;
}

.widget-cards,
.module-cards {
    border-radius: 25px;
    border: none !important;
    color: #fff;
}

.widget-cards .ant-card-body {
    padding: 20px 15px !important;
    display: flex;
}

.card-blue {
    background-color: #1890ff
}

.card-green {
    background-color: #2ecc71
}

.card-red {
    background-color: #e74c3c
}

.card-orange {
    background-color: #f39c12
}

.card-yellow {
    background-color: #f1c40f
}

.card-violet {
    background-color: #9b59b6
}

.card-black {
    background-color: #2c3e50
}

.widget-icon {
    font-size: xx-large;
    margin-right: 10px;
}

.home-widget-icon {
    font-size: xx-large;
}

.widget-cards .ant-card-body .widget-big-text {
    font-size: 35px;
    line-height: 30px;
    margin-bottom: 3px;
}

.widget-cards .ant-card-body .widget-default-text {
    font-size: 25px;
}

.module-cards,
.module-card-wrapper {
    width: 100%;
    cursor: pointer;
}

.module-cards {
    height: 150px;
}

.module-cards h4 {
    color: #fff;
}

.success-btn {
    background-color: #27ae60;
    border-color: #27ae60;
}

.success-btn:hover {
    background-color: #2ecc71;
    border-color: #2ecc71;
}

.payment-config .ql-editor {
    min-height: 100px !important;
}

.strike-text {
    text-decoration: line-through;
}

.clear-button {
    background-color: #ecf0f1;
    border-color: #bdc3c7;
}

.clear-button:hover {
    background-color: #bdc3c7;
    border-color: #bdc3c7;
    color: #fff;
}

.custom-ul {
    padding-inline-start: 10px !important;
}

.ticket-response-input .ql-editor {
    min-height: 100px !important;
}

.customer-support-note-modal {
    width: unset !important;
    max-width: 90%;
    min-width: 520px;
}

.request-image {
    cursor: pointer;
    border: 2px #bdc3c7 solid;
}

.ant-descriptions-item-content {
    display: block !important;
}

.smallParent>p {
    margin: 3px;
}

[data-theme='compact'] .site-collapse-custom-collapse .site-collapse-custom-panel,
.site-collapse-custom-collapse .site-collapse-custom-panel {
    background: #fff;
    border-radius: 2px;
    border-bottom: 1px solid #c0c0c06c;
    overflow: hidden;
}

.alink {
    color: #0071d8;
}

/**Edit table cell*/
.editable-cell {
    position: relative;
}

.editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
}

[data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
    border: 1px solid #434343;
}

/** End Edit table cell*/

.list-img-thumbnail {
    display: inline-block;
    position: relative;
    margin-right: 8px;
    border: 1px solid #dedede;
    padding: 5px;
    margin-bottom: 8px;
    width: 55px;
    height: 55px;
    overflow: hidden;
}

.list-img-thumbnail .btn-delete-img {
    position: absolute;
    z-index: 99;
    top: 0;
    right: 0;
    cursor: pointer;
    font-size: 12px;
    color: red;
    background: #fff;
    border-radius: 50%;
    padding: 6px;
}

.ant-statistic-content {
    font-size: 18px;
}

.ant-descriptions-item-label:hover {
    color: rgba(0, 0, 0, 0.85);
}

.ant-divider-horizontal {
    margin: 10px 0;
}

.icon-delete-comment {
    cursor: pointer;
    font-size: 12px;
    color: #bdc3c7;
}

.ant-comment-inner {
    padding: 8px 0;
}

.ant-comment-actions {
    margin-top: 0;
}

.ant-legacy-form-item {
    margin-bottom: 8px;
}

.ant-upload.ant-upload-select-picture-card,
.ant-upload-list-picture-card-container {
    width: 55px;
    height: 55px;
}

.ant-upload {
    font-size: 10px;
}

.ant-list-item-meta-avatar:empty {
    margin-right: 0;
}

.ant-list-sm .ant-list-item {
    padding: 8px 0;
}

.ant-tooltip-inner a {
    color: #0071d8;
}

.ant-comment-content-detail img {
    max-width: 100%;
}

.ant-comment-content-detail p {
    margin-bottom: 1rem;
}

.ant-tooltip-inner img, .p-description-detail img {
    max-width: 100%;
}

.ant-tooltip-inner p {
    margin: 0px;
}

.table-image img {
    height: 40px;
}

.table-image p {
    margin: 0;
    display: inline;
}

.paramer-content {
    margin-bottom: 10px;
}

.paramer-content>div {
    display: inline-block;
    margin: 0 10px;
}

.font-weight-500 {
    font-weight: 500;
}

.no-margin {
    margin: 5px 0 !important;
}

.border-hover:hover .ant-card-bordered,
.border-hover.active .ant-card-bordered {
    border: 1px solid #0071d8 !important;
}

.btn-add-page {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-between;
}

.icon-card-page.inactivePage {
    position: absolute;
    top: 6px;
    right: 16px;
    font-size: 20px;
    cursor: pointer;
    color: #dedede;
}

.icon-card-page.inactivePage:hover {
    color: red;
}

.icon-card-page.activePage {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    color: #dedede;
}

.icon-card-page.activePageChecked {
    color: #0071d8;
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
}

span.ant-descriptions-item-label {
    font-weight: 500;
}

.ant-input,
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
.ant-btn,
.ant-picker,
.ant-tag {
    border-radius: 5px;
}

.ant-input-search>.ant-input-group>.ant-input-group-addon:last-child .ant-input-search-button {
    border-radius: 0 5px 5px 0;
}

.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.ant-input-number {
    border-radius: 5px !important;
}

.rating-start.ant-rate {
    font-size: 14px !important;
}

.site-page-header {
    padding-left: 0 !important;
    padding-bottom: 0;
}

.dragger-upload-store>span {
    padding: 5px !important;
}

.flex-upload .ant-legacy-form-item-children {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-start;
    align-items: stretch;
}

.ant-space {
    flex-wrap: wrap;
}

.avatar-uploader-custom .ant-upload-select {
    overflow: hidden;
}

.no-border-buttom-table .ant-table-tbody>tr>td {
    border-bottom: none !important;
}

.ant-table-thead>tr>th {
    /* background: #f0f0f0; */
}

.box-info {
    padding: 15px;
    border: 1px solid #dedede;
    border-radius: 5px;
    /* box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px; */
}

.is_delete_item {
    text-decoration: line-through;
    color: red;
}

.fix-color-disabled-input .ant-input[disabled] {
    color: rgb(0 0 0);
}

.product-receipt-import-form .ant-upload-list-picture .ant-upload-list-item,
.product-receipt-import-form .ant-upload-list-picture-card .ant-upload-list-item {
    padding: 0px;
}

.product-receipt-import-form .ant-upload.ant-upload-select-picture-card,
.product-receipt-import-form .ant-upload-list-picture-card-container {
    width: 40px;
    height: 40px;
    margin: 0 4px 0px 0;
}

.product-receipt-import-form .ant-upload.ant-upload-select-picture-card {
    margin-right: 0;
    margin-bottom: 0;
}

/* .list-in-detail-customer {
    padding-left: 25px;
} */

.list-in-detail-customer .ant-list-item-action {
    margin-top: 0;
}

.list-in-detail-customer .ant-list-empty-text {
    padding: 0;
}

.list-in-detail-customer li {
    padding: 10px !important;
    border-radius: 5px;
}

.list-in-detail-customer li .border-dashed {
    background: none;
    border-color: cadetblue;
}

.dv-quan-tam li {
    background: #e7efea;
}

.dv-chot li {
    background: #e7ecd8;
}

.no-data,
.no-data .ant-card-body {
    text-align: center;
    color: #b8b5b5;
}

.wrap-customer-detail .ant-card-extra {
    color: #a3a3a3d9;
}

.wrap-customer-detail a {
    color: #188fff !important;
}

/***/
.list-img-thumbnail2 {
    display: inline-block;
    position: relative;
    margin-right: 8px;
    border: 1px solid #dedede;
}

.list-img-thumbnail2 .ant-radio-wrapper {
    margin-right: 0;
}

.list-img-thumbnail2 span.ant-radio+* {
    padding-right: 0;
    padding-left: 0;
}

.list-img-thumbnail2 .btn-delete-img {
    position: absolute;
    z-index: 99;
    top: 0;
    right: 0;
    background: #fff;
    cursor: pointer;
    font-size: 18px;
    color: red;
    opacity: 0.2;
}

.list-img-thumbnail2 .btn-delete-img2 {
    position: absolute;
    z-index: 99;
    top: -1px;
    right: -1px;
    background: #fff;
    cursor: pointer;
    font-size: 20px;
    color: rgb(190, 155, 155);
    padding: 6px;
    opacity: 0.7;
    border-radius: 0px 0px 0px 10px;
}

.list-img-thumbnail2 .btn-delete-img:hover,
.list-img-thumbnail2 .btn-delete-img2:hover {
    opacity: 1;
    color: red;
}

.list-img-thumbnail2 .ant-radio {
    position: absolute;
    z-index: 1;
    top: 4px;
    left: 4px;
}

.row-customer-appointment-schedule .ant-card {
    background: #fff9ed;
    height: 100%;
}

.row-customer-appointment-schedule .ant-card-bordered {
    border: 1px solid #f2eadb;
}

.row-customer-appointment-schedule .ant-card-head {
    border-bottom: 1px solid #f2eadb;
}

.fix-padding-left {
    padding-left: 0 !important;
}

.h6-title {
    font-size: 14px;
    font-weight: 500;
}

.row-voucher-customer-detail .ant-card {
    height: 100%;
}

.border-dashed {
    border: 1px dashed #dedede;
}

.card-service-detail {
    background: #40a9ff2b;
    height: 100%;
}

.card-service-detail.ant-card-bordered {
    border: 1px solid #108ee945;
}

.card-voucher-detail {
    background: #52c41a0d;
    height: 100%;
}

.card-voucher-detail.ant-card-bordered {
    border: 1px solid #52c41a36;
}

.card-voucher-detail .ant-card-head {
    border-bottom: 1px solid #52c41a36;
}

.row-customer-telesale .ant-card {
    background: #738d6621;
    height: 100%;
}

.row-customer-telesale .ant-card-bordered {
    border: 1px solid #738d6621;
}

.row-customer-telesale .ant-card-head {
    border-bottom: 1px solid #738d6621;
}

.row-customer-direct-consultant .ant-card {
    background: #465fa324;
    height: 100%;
}

.row-customer-direct-consultant .ant-card-bordered {
    border: 1px solid #465fa324;
}

.row-customer-direct-consultant .ant-card-head {
    border-bottom: 1px solid #465fa324;
}

.ant-modal-content {
    border-radius: 5px;
}

.ant-modal-header {
    border-radius: 5px 5px 0 0;
}

.ant-image-img, .ant-image-mask {
    border-radius: 6px;
}

.ant-card-bordered {
    border-radius: 5px;
}

.fix-height-input input {
    height: 20px;
    line-height: 20px;
}

.ant-descriptions-small .ant-descriptions-row>td, .ant-descriptions-small .ant-descriptions-row>th {
    padding-bottom: 6px;
    padding-top: 3px;
}

.ant-alert {
    border-radius: 5px;
}

.custom-badge {
    width: 100%;
    height: 100%;
    top: 8px;
}

.custom-badge sup {
    transform: none;
    top: -14px;
    left: 0;
    border-radius: 5px;
    right: auto;
}

.fix-alert-element {
    background-color: #52c41a0f;
    border: 1px solid #52c41a45;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 5px;
}


.chat-container {
    display: flex;
    flex-direction: column;
    padding: 10px;
    max-height: 400px;
    overflow-y: auto;
}

.message-container {
    margin-bottom: 15px;
    border-radius: 8px;
}

.message {
    padding: 8px;
    border-radius: 8px;
    max-width: 300px;
    word-break: break-word;
    clear: both;
    margin: 0;
}

.message-user {
    margin-bottom: 5px;
    color: #858585;
}

.right .message-user {
    float: right;
}

.left .message-user {
    float: left;
}

.right {
    align-self: flex-end;
    color: white;
}

.right .message {
    background-color: #4caf50;
}

.left {
    align-self: flex-start;
    color: white;
}

.left .message {
    background-color: #2196f3;
}

.input-container {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.message-time {
    color: #9b8e8e;
    font-size: 0.7rem;
}

.right .message-time {
    text-align: right;
}

.left .message-time {
    text-align: left;
}

.unread {
    font-weight: 600;
    color: rgba(0, 0, 0, 0.85);
    text-decoration: none
}

.readed {
    font-weight: normal;
    color: rgba(0, 0, 0, 0.588);
    text-decoration: none
}

.readed:hover, .unread:hover {
    text-decoration: none;
}

.status-notification .ant-badge-status-dot {
    width: 10px;
    height: 10px;
}

.light-text {
    font-weight: 700;
}

.chingo-content-detail table {
    width: 100%;
    max-width: 100%;
}

.chingo-content-detail table p {
    margin: 0;
}

.chingo-content-detail table td,
.chingo-content-detail table th {
    border: 1px solid #dedede;
    vertical-align: middle;
    padding: 0.5rem;
}

.chingo-content-detail img {
    max-width: 100%;
}

.app-comment-list .ant-comment-content-author>a, .ant-comment-content-author>span.ant-comment-content-author-name {
    font-weight: 700;
}

.ant-legacy-form-vertical .ant-legacy-form-item-label>label, .ant-col-24.ant-legacy-form-item-label>label, .ant-col-xl-24.ant-legacy-form-item-label>label {
    font-weight: 500;
}

.comment-content-detail .ant-comment-inner {
    padding: 4px 0;
}

.current-day-column {
    background: #52c41a4a;
}

/**Edit table cell*/
.editable-cell {
    position: relative;
}

.editable-cell-value-wrap {
    cursor: pointer;
    width: 100%;
}

.editable-row:hover .editable-cell-value-wrap {
    border-radius: 5px;
}

[data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {}

.shift-scheduling-list-table .ant-legacy-form-item-control {
    line-height: normal;
}

/** End Edit table cell*/


.fix-table-style-children .ant-table-thead tr th {
    padding: 2px 8px !important;
    border: 1px solid #f0f0f0;
}

.fix-table-style-children .ant-table-thead tr th::before {
    width: 0 !important;
    height: 0 !important;
}

/** Chat conversation*/

.card-messenger {
    margin-top: 15px !important;
    height: 89vh;
    max-height: 89vh;
    min-height: 89vh;
    overflow: hidden;
    border-radius: 5px !important;
}

@media screen and (max-width: 735px) {

    .card-messenger {
        height: 92vh !important;
        overflow: auto !important;
        max-height: 92vh !important;
        min-height: 92vh !important;
    }

    .conversation-info {
        display: none;
    }

    .wrap-conversation-chat-section {
        display: none;
    }

    .conversation-content ul li>div {
        max-width: 310px !important;
    }

    .conversation-footer {
        position: fixed !important;
        bottom: 0;
        left: 0;
        right: 0;
        border-top: 1px solid #dedede;
    }

    .conversation-content {
        max-height: 70vh !important;
        min-height: 70vh !important;
    }
}

.side-bar-conversation {}

.side-bar-conversation .ant-list-item {
    cursor: pointer;
    padding: 12px 8px;
}

.side-bar-conversation .ant-list-item.active,
.side-bar-conversation .ant-list-item:hover {
    background: #ddecf4;
    border-radius: 5px;
}

.side-bar-conversation::-webkit-scrollbar {
    width: 8px;
    background: none;
}

.side-bar-conversation::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, .3);
}

.side-bar-conversation .info {
    text-align: right;
}

.side-bar-conversation .date-time {
    font-size: 12px;
    color: #66666680;
}

.conversation-icon {
    font-size: 16px;
}

.has-new-message-icon {
    color: #e90303;
}

.has-new-message-name {
    font-weight: 700;
}

.has-new-message-snippet {
    font-weight: 700;
    color: #096dd9;
}

.side-bar-conversation .ant-list-item-meta-description {
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    line-height: 1.5715;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-weight: 400;
}

.conversation-header {
    border-bottom: 1px solid #dedede;
    padding-bottom: 10px;
    display: flex;
    align-content: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.conversation-header-page {
    text-align: right;
}

.conversation-header-title {
    display: flex;
}

.conversation-title {
    margin-left: 20px;
}

.conversation-title .name {
    font-size: 15px;
    font-weight: 500;
}

.conversation-title .status {
    color: #666666;
    font-size: 13px;
    margin-top: 5px;
}

.conversation-body {
    height: 63vh;
    /* border-bottom: 1px solid #dedede; */
}

.conversation-content {
    height: auto;
    min-height: 100%;
    max-height: 100%;
    overflow-y: scroll;
    overflow-x: hidden !important;
    width: 100%;
    flex-direction: column-reverse;
    display: flex;
}

.conversation-content .infinite-scroll-component {
    overflow-x: hidden !important;
}

.conversation-content::-webkit-scrollbar {
    width: 8px;
    background: none;
}

.conversation-content::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, .3);
}

.conversation-content ul {
    padding: 0;
    margin: 0;
    width: 100%;
}

.conversation-content ul li {
    display: inline-block;
    width: calc(100% - 25px);
}

.conversation-content ul li.messages {
    margin: 25px 15px 5px;
}

.conversation-content ul li.comment {
    margin: 40px 15px 5px;
}

.conversation-content ul li .msg-content {
    display: inline-block;
    border-radius: 8px;
    max-width: 400px;
    line-height: 130%;
    font-size: 1.1em;
    position: relative;
    min-width: 180px;
    color: #ffffff;
    word-break: break-word;
}

.conversation-content ul li .text-msg {
    padding: 10px 15px;
}

.conversation-content ul li .attachment-msg {
    overflow: hidden;
}

.conversation-content ul li.replies>.msg-content {
    background: #4caf50;
    float: right;
}

.conversation-content ul li.sent .msg-content {
    background: #2196f3;
    color: #ffffff;
}

.conversation-content ul li .msg-content.attachments {
    background: none;
}

.conversation-content ul li.replies .msg-content.attachments {
    text-align: right;
}

.conversation-content ul li.replies>.msg-content .icon-action-msg {
    right: 0;
}

.conversation-content ul li .icon-action-msg {
    position: absolute;
    bottom: -38px;
    color: #8b7e7e;
}

.icon-action-msg div.wrap-icon .icon {
    margin-right: 10px;
}

.icon-action-msg .icon {
    cursor: pointer;
}

.icon-action-msg .icon:hover {
    color: #0088b9;
}

.icon-action-msg .icon.eye.active {
    color: red;
}

.icon-action-msg .icon.like.active {
    color: rgb(0, 60, 255);
}

.conversation-content ul li.sent .msg-info {
    position: absolute;
    font-size: 11px;
    left: 0;
    bottom: -20px;
    padding: 0;
    background: none;
    color: #666;
    margin: 0 -100% 0 0;
}

.conversation-content ul li.replies .msg-info {
    position: absolute;
    font-size: 11px;
    right: 0;
    bottom: -20px;
    padding: 0;
    background: none;
    color: #666;
    margin: 0 0 0 -100%;
}

.conversation-content ul li div .msg-info .msg-time {
    background: none;
    margin: 0;
    padding: 0;
}

.conversation-content ul li.replies .avata {
    float: right;
    margin: 0 0 0 8px;
}

.conversation-content ul li.sent .avata {
    margin: 0 8px 0 0;
}

.conversation-footer {
    padding: 10px 0;
    height: 100px;
    position: relative;
    background: #fff;
    border-top: 1px solid #dedede;
}

.conversation-footer .loading-messages {
    position: absolute;
    margin-top: -40px;
    margin-left: 5px;
    font-size: 20px;
}

.conversation-footer .wrap .avata-page {
    position: absolute;
    border-radius: 5px;
    width: 30px;
    height: 30px;
    margin-top: 8px;
    margin-left: 8px;
}

.conversation-footer .message-input textarea {
    width: 100%;
    padding: 10px;
    border: none;
    color: #32465a;
    overflow: hidden;
    resize: none;
    box-sizing: border-box;
    position: absolute;
    bottom: 40px;
    transition: height 0.2s;
    min-height: 58px;
    max-height: 250px;
    overflow-y: auto;
}

.conversation-footer .message-input textarea::-webkit-scrollbar {
    width: 4px;
    background: none;
}

.conversation-footer .message-input textarea::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, .3);
}

.conversation-footer .list-attachment-icon-wrap {
    display: flex;
    justify-content: flex-end;
    align-content: center;
    align-items: stretch;
    flex-wrap: wrap;
    position: absolute;
    bottom: 0;
    right: 0;
}

.conversation-footer .list-attachment-icon-wrap button {
    border: none;
    cursor: pointer;
    background: no-repeat;
    padding: 0;
    outline: none;
}

.conversation-footer .list-attachment-icon-wrap .icon {
    cursor: pointer;
    z-index: 4;
    font-size: 24px;
    margin: 0 4px;
    color: #dedede;
    padding: 5px;
}

.conversation-info b {
    font-weight: 500;
}

.conversation-info .ant-descriptions-small .ant-descriptions-row>td, .ant-descriptions-small .ant-descriptions-row>th {
    padding: 8px 0;
}

.wrap-conversation-info {
    height: 85vh;
    overflow-y: scroll;
    overflow-x: hidden;
    min-height: 85vh;
    max-height: 85vh;
    padding-right: 5px;
}

.wrap-conversation-info::-webkit-scrollbar {
    width: 8px;
    background: none;
}

.wrap-conversation-info::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, .3);
}

.filter-icon-conversation {
    color: #0071d8 !important;
    font-size: 20px;
    cursor: pointer;
    margin-left: 10px;
}

.wrap-list-page-filter {
    padding: 8px 0;
    border-bottom: 1px solid #eaeaea;
}

.list-page-filter {
    display: flex;
}

.list-page-filter-page-name .pageid {
    color: #929090;
}

.list-page-filter-page-name {
    margin-left: 10px;
}

.fix-ant-spin-nested-loading {
    margin: auto;
}

.has-custom-column-table-btn {
    position: relative;

}

.custom-column-table-btn {
    position: absolute;
    right: 8px;
    top: 6px;
    z-index: 99;
}

.ant-tabs-content .custom-column-table-btn {
    top: -46px
}

.has-custom-column-table-btn .ant-tabs>.ant-tabs-nav, .ant-tabs>div>.ant-tabs-nav {
    margin-right: 80px;
}

.row-group-input .ant-col {}

.has-table-summary .ant-table-summary {
    position: relative;
    z-index: 2;
    background: #0b2b3347 !important;
    font-weight: 500;
}

.has-table-summary .ant-table-summary .ant-table-cell {
    padding: 3px 8px;
}

.custom-table .ant-table-title {
    background: #ededed;
    font-size: 16px;
    font-weight: 500;
}

.custom-input-number {
    background: #fff;
    border: none;
}

.custom-input-number input {
    text-align: right;
    font-size: 16px;
    font-weight: 600;
    color: green;
    padding-right: 0;
}

.custom-input-number .ant-input-number-group-addon {
    background: #fff;
    border: none;
    font-size: 16px;
    color: green;
    font-weight: 600;
    padding-left: 0;
}

.receipt-net-total {
    color: #52c41a;
}

.receipt-net-total .ant-legacy-form-item-label {
    padding-bottom: 0;
}

.form-item-label-right {
    padding-bottom: 0 !important;
}

.form-item-label-right .ant-legacy-form-item-label {
    text-align: right;
    padding: 0 20px 8px 0;
    line-height: 30px;
}

.wrap-customer-detail .list-action-button {
    position: sticky;
    top: 0;
    z-index: 1000;
    /* Đảm bảo các nút nằm trên các thành phần khác */
    background-color: white;
    /* Đảm bảo nền phía sau không bị trùng với nội dung cuộn */
    padding: 10px;
}

.form-item-label-right .ant-input-number-disabled {
    background-color: #fff;
    color: #000000d9;
}

.desabled-input-table .ant-input-number-disabled {
    background-color: #fff;
    color: #000000d9;
    border: none;
}

.calling-animation {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #4CAF50;
    animation: pulse 1.5s infinite ease-in-out;
}

.calling-animation-end {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #e6282e;
}

.calling-animation::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: rgba(76, 175, 80, 0.3);
    transform: translate(-50%, -50%);
    animation: ripple 1.5s infinite ease-in-out;
}

@keyframes pulse {
    0% {
        transform: scale(0.9);
        box-shadow: 0 0 0 0 rgba(76, 175, 80, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(76, 175, 80, 0);
    }

    100% {
        transform: scale(0.9);
        box-shadow: 0 0 0 0 rgba(76, 175, 80, 0);
    }
}

@keyframes ripple {
    0% {
        transform: translate(-50%, -50%) scale(0.8);
        opacity: 1;
    }

    100% {
        transform: translate(-50%, -50%) scale(1.5);
        opacity: 0;
    }
}

.phone-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    color: white;
}

.customer-data-statistic {
    width: 25%;
    border-radius: 0 !important;
}

.customer-data-statistic .ant-card-body {
    padding: 10px !important;
}

.customer-data-statistic .ant-statistic-title {
    font-size: 14px;
}

.customer-data-statistic .ant-statistic-content {
    font-size: 16px;
}

.tabs-custom .ant-tabs-nav {
    position: sticky;
    top: 0;
    z-index: 1000;
    margin: 0;
    overflow: hidden;
    padding: 5px 5px;
}

.tabs-custom .search-customer {
    position: sticky;
    top: 48px;
    z-index: 999;
    padding: 10px 0 0 0;
    background: #fff;
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.tabs-custom .ant-tabs-tab {
    background: transparent !important;
    border: none !important;
    color: #fff;
}

.tabs-custom .ant-tabs-tab:last-child {
    margin-right: 50px;
}

.tabs-custom .ant-tabs-tab.ant-tabs-tab-active {
    background-color: #ffffff !important;
}

.tabs-custom .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #0b2b33 !important;
}

.tabs-custom .ant-tabs-nav-more {
    color: #fff;
}

.tabs-custom-children .ant-tabs-tab {
    background: transparent !important;
    border: none !important;
    color: #183940;
}

.tabs-custom-children .ant-tabs-nav {
    z-index: 999;
}

.customer-action-bar {
    position: sticky;
    top: 122px;
    z-index: 1000;
    text-align: center;
}

@media only screen and (max-width: 768px) {
    .customer-action-bar .ant-btn {
        font-size: 10px;
        padding: 5px;
        /* background: #096dd9; */
        border: none;
    }

    .customer-action-bar .ant-btn span {
        display: block;
        line-height: 10px;
        white-space: normal;
        text-align: center;
        width: 55px;
    }

    .ant-drawer-body {
        padding: 20px 10px;
    }

    .tabs-custom .search-customer {
        margin-left: -8px !important;
        margin-right: -8px !important;
    }

    .tabs-custom .ant-tabs-nav {
        padding: 5px 0;
    }
}

.footer-action-bar {
    border-top: 1px solid #d9d9d9;
    position: sticky;
    bottom: 0;
    z-index: 1000;
    background: #f4f4f4;
    text-align: center;
    padding: 10px 0;
    width: 110%;
    margin-top: 40px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    margin-left: -5px;
    margin-right: -5px;
    margin-bottom: -5px;
}

.customer-family-delete-icon {
    position: absolute;
    top: -18px;
    right: -14px;
    font-size: 12px;
    cursor: pointer;
    color: #ff0000;
    background: #fff;
    border: 1px solid #dedede;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 25px;
}

.customer-info-data .ant-collapse-icon-position-end>.ant-collapse-item>.ant-collapse-header {
    padding: 5px 0px;
    border-bottom: 1px solid #0000000f;
}

.customer-info-data .ant-collapse-ghost>.ant-collapse-item>.ant-collapse-content>.ant-collapse-content-box {
    padding-left: 0;
}

.customer-info-data .ant-descriptions-item-content {
    display: inline-block !important;
}

.list-in-detail-customer .ant-list-item-action {
    display: flex;
}

.customer-data-customer-service-form .ant-collapse-icon-position-end>.ant-collapse-item>.ant-collapse-header {
    padding: 10px 0px 10px 10px;
    border-bottom: 1px solid #fff;
    background: #0b2b331a;
}

.customer-data-customer-service-form .examination-visit .ant-collapse-icon-position-end>.ant-collapse-item>.ant-collapse-header {
    padding: 10px 0px;
    border-bottom: 1px solid #0000001f;
    background: none;
}

.customer-data-customer-service-form .ant-descriptions-item-label {
    min-width: 90px;
}

.customer-data-customer-service-form .ant-legacy-form-item-control {
    line-height: 1.5715;
}

.chingo-normal-title {
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    line-height: 1.5715;
    text-align: start;
    font-weight: 700;
}

.customer-service-form-footer-action {
    display: flex;
    gap: 10px;
    flex-wrap: nowrap;
    margin-bottom: 10px;
}

.customer-service-form-footer-action button {
    width: 100%;
    margin-top: 20px;
}

.customer-service-form-header-action {
    display: flex;
    gap: 10px;
    margin: 10px 0;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}

.service-card-box {
    border-bottom: 1px dashed #d9d9d9;
    margin-bottom: 20px;
    padding-bottom: 20px;
    position: relative;
}

.service-card-box:last-child {
    border-bottom: none;
}

.customer-data-customer-service-form .ant-collapse-content>.ant-collapse-content-box {
    padding: 10px;
}

.examination-visit-doctor-list {
    margin: 0;
    padding: 0;
}

.examination-visit-doctor-list li {
    padding: 8px 0;
    border-bottom: 1px solid #0000000f;
    list-style: none;
}

.examination-visit-doctor-list li:last-child {
    border-bottom: none;
}

.box-item-mobile .ant-card {
    background: #fff;
    height: 100%;
}

.box-item-mobile .ant-card-head {
    border-bottom: 1px dashed #c5c5c5;
}

.box-item-mobile.box-item-mobile-header {
    margin-bottom: 14.5px !important;
}

.box-item-mobile {
    margin-bottom: 14.5px;
}

.box-item-mobile .ant-collapse-ghost>.ant-collapse-item>.ant-collapse-content>.ant-collapse-content-box {
    padding-top: 8px;
    padding-bottom: 0px;
}

.box-item-mobile .customer-service-box .ant-collapse-ghost>.ant-collapse-item>.ant-collapse-content>.ant-collapse-content-box,
.box-item-mobile .customer-telesale-box .ant-collapse-ghost>.ant-collapse-item>.ant-collapse-content>.ant-collapse-content-box {
    padding: 8px 0;
}

.box-item-mobile .customer-telesale-box .ant-collapse-icon-position-end>.ant-collapse-item>.ant-collapse-header {
    padding: 10px 0px;
    border-bottom: 1px solid #0000000f;
}

.box-item-mobile .customer-telesale-box.no-border .ant-collapse-icon-position-end>.ant-collapse-item>.ant-collapse-header,
.box-item-mobile .customer-service-box.no-border .ant-collapse-icon-position-end>.ant-collapse-item>.ant-collapse-header,
.customer-data-customer-service-form .examination-visit .ant-collapse-icon-position-end>.ant-collapse-item>.ant-collapse-header {
    border: none;
}

.customer-data-statistic-row {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

@media only screen and (max-width: 768px) {
    .box-item-mobile {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .box-item-mobile .ant-card {
        border-radius: 0 !important;
    }

    .box-item-mobile.box-item-mobile-header {
        margin-bottom: auto !important;
    }

    .ant-drawer-header {
        padding: 16px 5px;
    }

    .item-profile-image {
        padding: 0 !important;
    }

    .customer-data-statistic-row {
        margin-left: -8px !important;
        margin-right: -8px !important;
    }

    .assistant-doctor .box-item-mobile {
        margin-bottom: 5px;
    }
}

.menu-icon-mobile {
    font-size: 20px;
    color: #fff;
    position: fixed;
    top: -2px;
    right: 0;
    background: #0b2b33;
    padding: 9px 10px;
    cursor: pointer;
    z-index: 1000;
    border-left: 1px solid #fff;
}

.font-weight-500 {
    font-weight: 500 !important;
}

.item-profile-image {
    border-bottom: 1px dashed #dedede;
}

.icon-fa-reply {
    font-size: 12px;
    color: #fac400;
}

.icon-fa-share {
    font-size: 12px;
    color: red;
}

.responsive-media-image {
    object-fit: cover;
    object-position: center;
    height: 150px;
}

@media (min-width: 576px) {
    .responsive-media-image {
        height: 140px;
    }
}

@media (min-width: 768px) {
    .responsive-media-image {
        height: 160px;
    }
}

@media (min-width: 992px) {
    .responsive-media-image {
        height: 180px;
    }
}

.file-media-card .ant-card-actions>li {
    margin: 4px 0;
}

@media only screen and (max-width: 768px) {
    .card-search-customer .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
        height: 55px;
    }

    .card-search-customer .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
        line-height: 55px;
    }

    .card-search-customer .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector::after, .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item, .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
        line-height: 55px;
    }

    .card-search-customer .ant-select-lg {
        font-size: 18px;
    }
}

.customer-data-assistant-service-list-detail .ant-collapse-icon-position-end>.ant-collapse-item>.ant-collapse-header {
    position: relative;
    padding: 12px 0px;
    padding-right: 40px;
    border-bottom: 1px solid #dedede;
}

.customer-data-assistant-service-list-detail .ant-collapse-ghost>.ant-collapse-item>.ant-collapse-content>.ant-collapse-content-box {
    padding: 12px 0;
}

.customer-data-assistant-service-list-detail .ant-collapse-icon-position-end>.ant-collapse-item>.ant-collapse-header {
    padding: 10px 0 10px 10px;
    border-bottom: 1px solid #fff;
    background: rgba(11, 43, 51, .10196078431372549);
}

.examination_visit-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
}

.line-profile-image {
    padding: 5px;
    margin-bottom: 10px;
    position: relative;
}

.profile-image-delete-button {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    z-index: 10;
}

.fix-padding-collapse .ant-collapse-content>.ant-collapse-content-box {
    padding: 0 !important;
}

.fix-padding-collapse .ant-collapse>.ant-collapse-item>.ant-collapse-header {
    padding: 12px 0px !important;
}

.wrap-customer-detail .ant-descriptions-small .ant-descriptions-row>td, .ant-descriptions-small .ant-descriptions-row>th {
    padding-bottom: 0px;
    padding-top: 0px;
}

/*** CUSTOMER DETAIL COLLAPSE ***/
[data-theme='compact'] .customer-detail-collapse .customer-detail-collapse-panel,
.customer-detail-collapse .customer-detail-collapse-panel {
    margin-bottom: 2px;
    overflow: hidden;
    background: #fff;
    border-radius: 2px;
    border-bottom: 1px solid #d9d9d9;
}

.customer-detail-collapse .ant-collapse-content-box {
    padding: 12px 0;
}

.customer-detail-collapse .ant-collapse-header {
    padding-left: 0 !important;
    font-weight: 600;
}

/*** END CUSTOMER DETAIL COLLAPSE ***/

.box-item-service .ant-card {
    background: #fff;
    height: 100%;
}

.box-item-service .ant-card-head {
    border-bottom: 1px dashed #c5c5c5;
}

.box-item-service.box-item-mobile-header {
    margin-bottom: 14.5px !important;
}

.box-item-service {
    margin-bottom: 14.5px;
}

.box-item-service .ant-collapse-ghost>.ant-collapse-item>.ant-collapse-content>.ant-collapse-content-box {
    padding-top: 8px;
    padding-bottom: 0px;
}

.box-item-service .customer-service-box .ant-collapse-ghost>.ant-collapse-item>.ant-collapse-content>.ant-collapse-content-box,
.box-item-service .customer-telesale-box .ant-collapse-ghost>.ant-collapse-item>.ant-collapse-content>.ant-collapse-content-box {
    padding: 8px 0;
}

.box-item-service .customer-telesale-box .ant-collapse-icon-position-end>.ant-collapse-item>.ant-collapse-header {
    padding: 10px 0px;
    border-bottom: 1px solid #0000000f;
}

.box-item-service .customer-telesale-box.no-border .ant-collapse-icon-position-end>.ant-collapse-item>.ant-collapse-header,
.box-item-service .customer-service-box.no-border .ant-collapse-icon-position-end>.ant-collapse-item>.ant-collapse-header,
.customer-data-customer-service-form .examination-visit .ant-collapse-icon-position-end>.ant-collapse-item>.ant-collapse-header {
    border: none;
}