.stringee_clound {
    /* position: fixed; */
    right: 10px;
    top: 10px;
    z-index: 99;
}

.text-center {
    text-align: center;
}

.m-auto {
    margin: auto;
}

.mb-5 {
    margin-bottom: 5px;
}

.mr-10 {
    margin-right: 10px;
}

.mr-20 {
    margin-right: 20px;
}

.mr-50 {
    margin-right: 50px !important;
}

.ml-10 {
    margin-left: 10px;
}

.ml-20 {
    margin-left: 20px;
}

.ml-50 {
    margin-left: 50px !important;
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-30 {
    margin-bottom: 30px;
}

.mb-40 {
    margin-bottom: 40px;
}

.mt-5 {
    margin-top: 5px;
}

.mt-10 {
    margin-top: 10px;
}

.mt-15 {
    margin-top: 15px;
}

.mt-15-negative {
    margin-top: -15px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-30 {
    margin-top: 30px;
}

.mt-50 {
    margin-top: 50px;
}

.mt-80 {
    margin-top: 80px;
}

.mt-100 {
    margin-top: 100px;
}

.p-0 {
    padding: 0px !important;
}

.p-5 {
    padding: 5px;
}

.p-10 {
    padding: 10px;
}

.p-15 {
    padding: 15px;
}

.p-20 {
    padding: 20px;
}

.p-l-r-10 {
    padding-left: 10px;
    padding-right: 10px;
}

.p-l-r-20 {
    padding-left: 20px;
    padding-right: 20px;
}

.pb-10 {
    padding-bottom: 10px;
}

.pb-20 {
    padding-bottom: 20px;
}

.pt-10 {
    padding-top: 10px;
}

.pt-20 {
    padding-top: 20px;
}

.pl-15 {
    padding-left: 15px;
}

.pl-20 {
    padding-left: 20px;
}

.pr-15 {
    padding-right: 15px;
}

.pr-20 {
    padding-right: 20px;
}

.left-10 {
    left: 10px;
}

.top-0 {
    top: 0px;
}

.top-5 {
    top: 5px;
}

.top-10 {
    top: 10px;
}

.top-15 {
    top: 15px;
}

.top-20 {
    top: 20px;
}

.top-200 {
    top: 200px;
}

.bottom-0 {
    bottom: 0px;
}

.bottom-10 {
    bottom: 10px;
}

.width-100-percent {
    width: 100%;
}

.width-35 {
    width: 35px;
}

.width-50 {
    width: 50px;
}

.height-100-percent {
    height: 100%;
}

.height-100-vh {
    height: 100vh;
}

.height-30 {
    height: 30px;
}

.height-35 {
    height: 35px;
}

.height-40 {
    height: 40px;
}

.height-50 {
    height: 50px;
}

.height-350 {
    height: 350px;
}

.right-0 {
    right: 0px;
}

.right-15 {
    right: 15px;
}

.right-20 {
    right: 20px;
}

.right-30 {
    right: 30px;
}

.right-40 {
    right: 40px;
}

.display-none {
    display: none !important;
}

.display-initial {
    display: initial !important;
}

.display-table {
    display: table;
}

.display-flex {
    display: flex;
}

.justify-content-space-between {
    justify-content: space-between;
}

.justify-content-space-evenly {
    justify-content: space-evenly;
}

.display-table-cell {
    display: table-cell;
}

.font-size-12 {
    font-size: 12px;
}

.font-size-16 {
    font-size: 16px;
}

.font-size-18 {
    font-size: 18px;
}

.font-size-20 {
    font-size: 20px;
}

.font-size-24 {
    font-size: 24px;
}

.font-size-25 {
    font-size: 25px;
}

.font-size-30 {
    font-size: 30px;
}

.position-relative {
    position: relative;
}

.position-absolute {
    position: absolute;
}

.outline-none {
    outline: none;
}

.z-index-10 {
    z-index: 10;
}

.z-index-20 {
    z-index: 20;
}

.z-index-100 {
    z-index: 100;
}

.border-none {
    border: none;
}

.btn-round {
    -webkit-border-radius: 3rem;
    -moz-border-radius: 3rem;
    border-radius: 3rem;
}

.border-radius-8 {
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
}

.border-radius-12 {
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    border-radius: 12px;
}

.border-radius-16 {
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    border-radius: 16px;
}

.border-radius-18 {
    -webkit-border-radius: 18px;
    -moz-border-radius: 18px;
    border-radius: 18px;
}

.color-white {
    color: #fff;
}

.color-black {
    color: #333;
}

.bg-transparent {
    background: transparent !important;
}

.bg-red {
    background: #d80303;
}

.bg-pink {
    background: #FF3366;
}

.bg-black {
    background: #4e4e4e;
}

.bg-white {
    background: #fff !important;
}

.bg-gradient-green {
    background: linear-gradient(254.56deg, #66D54B 6.49%, #17C653 108.73%);
}

.bg-gradient-purple {
    /* Rectangle */
    background: linear-gradient(90deg, #818FF5 0%, #A0A4E9 93.03%);
}

.bg-purple {
    background: #9498E1;
}

.bg-light-gray {
    background: #F9FBFC !important;
}

.bg-light-green {
    background: #63DC93 !important;
}

.bg-light-blue {
    background: #56D7D0 !important;
}

.float-right {
    float: right;
}

.float-left {
    float: left;
}

.line-height-30 {
    line-height: 30px !important;
}

.line-height-26 {
    line-height: 26px;
}

.flex-basis {
    flex-basis: 100%;
}

.overflow-hidden {
    overflow: hidden;
}

.box-shadow1 {
    box-shadow: 0px 12px 45px rgba(51, 49, 140, 0.25);
}

.box-shadow2 {
    box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.05);
}

.box-shadow3 {
    box-shadow: 0px 0px 15px rgba(51, 49, 140, 0.49);
}

.btn {
    text-align: center;
}

.btn-red {
    background: linear-gradient(262.92deg, #FF5B65 6.49%, #EF3B67 108.73%);
    box-shadow: 0px 4px 24px rgba(176, 43, 67, 0.25);
}

.btn-green {
    background: linear-gradient(262.92deg, #66D54B 6.49%, #17C653 108.73%);
    box-shadow: 0px 4px 24px rgba(50, 153, 150, 0.25);
}

.btn-size-25 {
    width: 25px;
    height: 25px;
}

.btn-size-28 {
    width: 30px;
    height: 30px;
}

.btn-size-30 {
    width: 30px;
    height: 30px;
}

.btn-size-35 {
    width: 35px;
    height: 35px;
}

.btn-size-55 {
    width: 55px;
    height: 55px;
}

.visibility-hidden {
    visibility: hidden;
}

.cursor-pointer {
    cursor: pointer;
}

.pointer-events-none {
    pointer-events: none;
}

/*# sourceMappingURL=util.css.map */
.color-green {
    color: #2bb409;
}

.color-red {
    color: #ff0000;
}

.mt-150 {
    margin-top: 150px;
}

* {
    outline: none;
}

button {
    cursor: pointer;
}

.action-call:hover {
    background: #f6fbfb !important;
}

.action-call:hover .drop-down-action {
    display: initial !important;
}

.action-call.active .icon {
    display: none;
}

.action-call.active .icon-on {
    display: initial !important;
}

.bg-transparent .toolbar-item:hover {
    background: #acb1f3 !important;
}

.toolbar-item:hover {
    background: #f6fbfb !important;
}

.diapad-key:hover {
    background: #f2f7fe;
}

.contact-item-call button {
    transition: none !important;
}

.btn-call-item {
    width: 28px;
    height: 28px;
    line-height: 28px;
}

.btn-call-item img {
    width: 12px;
}

#stringee_clound {
    position: relative;
    width: 287px;
    height: 538px;
    background: #FFFFFF;
    border-radius: 18px;
    /* box-shadow: 0 10px 30px 0 rgb(0 0 0 / 15%); */
}

#app {
    width: 100%;
    height: 100%;
    position: absolute;
    background: #fff;
}

.status-text {
    /* Currently in call.. */
    width: 100px;
    height: 15px;
    line-height: normal;
    font-size: 12px;
    letter-spacing: 0.03em;
    color: #BFBFC4;
}

.wrap-status-time {
    width: 74px;
    height: 33px;
    background: linear-gradient(254.56deg, #66D54B 6.49%, #17C653 108.73%);
    box-shadow: 0px 4px 24px rgba(50, 153, 150, 0.25);
    border-radius: 12px 0px 0px 12px;
    padding-left: 15px;
}

.wrap-agent-time {
    /* Rectangle 2.3 */
    width: 74px;
    height: 33px;
    background: linear-gradient(254.56deg, #f93232 6.49%, #fb6969 108.73%);
    box-shadow: 0px 4px 24px rgba(50, 153, 150, 0.25);
    border-radius: 12px 0px 0px 12px;
}

.status-time {
    /* 00:08 */
    width: 35px;
    height: 15px;
    line-height: normal;
    font-size: 12px;
    letter-spacing: 0.03em;
    color: #FFFFFF;
}

.agent-time {
    width: 35px;
    height: 15px;
    line-height: normal;
    font-size: 12px;
    letter-spacing: 0.03em;
    margin-left: 10px;
    color: #FFFFFF;
}

.line-red-short {
    /* Rectangle 3.3 */
    position: absolute;
    width: 30px;
    height: 2px;
    background: #FF5B65;
    border-radius: 12px;
}

.toast {
    background-color: #e9e9e9;
    position: fixed;
    top: 0px;
    width: 95%;
    z-index: 9;
    color: white;
    padding: 8px;
    height: auto;
    font-weight: 700;
    font-size: 12px;
    border-radius: 10px 10px 0px 0px;
    margin: 0px auto;
    right: 0px;
    left: 0px;
    word-break: break-all;
}

.toast.toast-error {
    background-color: #F44336;
}

.toast.toast-success {
    background-color: #4bcc50;
}

.toast.toast-warning {
    background-color: #ffc107;
}

.info-name {
    /* Pattrick Penna */
    font-size: 24px;
    color: #333333;
}

.info-name a.contact-info-name {
    text-decoration: none;
    border-bottom: 1px dashed #bebebe;
    color: #4a4a4a;
}

.info-name a.contact-info-name:hover {
    font-weight: 550;
}

.callstart-time {
    text-align: center;
}

.location-via {
    font-size: 12px;
    color: #BFBFC4;
}

.location-text {
    color: #7272aa;
}

.location-queue {
    font-size: 16px;
    color: #f7f7ec;
}

.location-queuetime {
    font-size: 15px;
    color: #f7f7ec;
}

.line-dotted {
    /* Line */
    padding-top: 16px;
    height: 1px;
    width: 100%;
    border-bottom: 1px dotted #EBEDF7;
}

.wrap-avatar-round img {
    border-radius: 36px;
    border: 4px solid #fff;
    width: 30%;
    margin-top: 20px;
}

.wrap-toolbar .toolbar-item {
    padding: 15px;
}

.page {
    position: absolute;
    left: 0px;
    top: 40px;
    width: 100%;
    height: calc(100% - 40px - 50px);
}

.incomming-call-info {
    width: 90%;
    margin-left: 5%;
    height: 80%;
}

.incomming-call-action {
    width: 90%;
    margin-left: 5%;
}

.diapad-row {
    display: flex;
    justify-content: space-between;
}

.diapad-key {
    width: 100%;
    border: none;
    background: #fff;
}

.diapad-key-number {
    display: block;
    font-size: 30px;
    color: #3583DE;
}

.diapad-key-text {
    font-size: 10px;
    color: #ADB0DA;
    display: block;
    height: 15px;
}

.call-using-text {
    color: #B8C9E2;
}

.call-using-select {
    background: #F2F7FE;
    border-radius: 8px;
}

.call-using-text-name {
    font-size: 14px;
    display: block;
}

.call-using-text-phone {
    font-size: 12px;
    color: #8E9CCC;
}

.call-using-dropdown {
    max-height: 180px;
    overflow-y: auto;
    position: absolute;
    width: calc(100% - 40px);
    left: 20px;
    bottom: -8px;
    z-index: 100;
}

.call-using-dropdown .call-using-dropdown-item {
    border-bottom: 1px dashed #EBEDF7;
}

.call-using-dropdown .call-using-dropdown-item:last-child {
    border-bottom: none;
}

.call-using-dropdown .call-using-dropdown-item:hover {
    background: #F2F7FE;
}

.wrap-call-using-dropdown .icon-dropdown {
    position: absolute;
    z-index: 120;
    bottom: -16px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 8px solid #fff;
}

/* Style select beauty */
.select-beauty-text {
    font-size: 10px;
    color: #B8C9E2;
}

.select-beauty-select {
    background: #F2F7FE;
    border-radius: 8px;
}

.select-beauty-text-name {
    font-size: 14px;
    display: block;
}

.select-beauty-text-phone {
    font-size: 12px;
    color: #8E9CCC;
}

.select-beauty-dropdown {
    max-height: 180px;
    overflow-y: auto;
    position: absolute;
    width: calc(100% - 40px);
    left: 20px;
    bottom: -8px;
    z-index: 100;
}

.select-beauty-dropdown .select-beauty-dropdown-item {
    border-bottom: 1px dashed #EBEDF7;
}

.select-beauty-dropdown .select-beauty-dropdown-item:last-child {
    border-bottom: none;
}

.select-beauty-dropdown .select-beauty-dropdown-item:hover {
    background: #F2F7FE;
}

.wrap-select-beauty-dropdown .icon-dropdown {
    position: absolute;
    z-index: 120;
    bottom: -16px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 8px solid #fff;
}

/* End style select beauty*/
.contact-filter-item {
    border-radius: 12px;
    background: #DBDBE3;
    color: #fff;
}

.contact-filter-item.active {
    background: #A1A5EA;
}

.input-search {
    width: 199px;
    height: 36px;
    background: #E7ECEF;
    border-radius: 30px 0px 0px 30px;
    font-size: 15px;
    color: #000;
}

.wrap-contact-list {
    height: 395px;
    overflow-y: auto;
    overflow-x: hidden;
    visibility: hidden;
}

.wrap-contact-list:hover {
    visibility: visible;
}

.wrap-contact-list-content {
    visibility: visible;
}

.contact-group-item {
    border: 1px dotted #DBDFED;
    border-right: none;
    border-radius: 18px 0px 0px 18px;
    background: #F9FBFC;
}

.contact-group-item .contact-item:last-child {
    border-bottom: none;
}

.wrap-contact-item-avatar {
    border: 2px solid #fff;
    position: relative;
    -moz-box-shadow: 0 0 20px #e0f3ec;
    -webkit-box-shadow: 0 0 20px #e0f3ec;
    box-shadow: 0 0 20px #e0f3ec;
}

.contact-group-name {
    font-size: 12px;
    color: #9EB1D6;
    padding: 10px;
}

.contact-item-status {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    top: -2px;
    right: -2px;
    position: absolute;
    background: #e0e0e0;
}

.status-online {
    background: #4FEEA7;
}

.status-offline {
    background: #FF5B6D;
}

.contact-item {
    padding: 10px;
    padding-left: 0px;
    margin-left: 15px;
    border-bottom: 1px dashed rgba(51, 49, 140, 0.25);
    position: relative;
}

.contact-item:hover {
    cursor: pointer;
    margin-left: -20px;
    border-bottom: none;
    zoom: 1.2;
    margin-top: -2px;
    background: #fff;
    border-radius: 24px 0px 0px 24px;
    box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.08);
    padding-left: 10px;
}

.contact-item:hover .visibility-hidden {
    visibility: visible;
}

.wrap-contact-item-info {
    padding-left: 10px;
}

.contact-item-name {
    font-size: 14px;
    color: #333;
}

.contact-item-phone {
    font-size: 12px;
    color: #B6B6C4;
}

.contact-item-call {
    position: absolute;
    right: 5px;
    top: 15px;
}

.wrap-activity-list {
    height: 428px;
    overflow-y: auto;
    overflow-x: hidden;
    visibility: hidden;
}

.wrap-activity-list:hover {
    visibility: visible;
}

.wrap-activity-list-content {
    visibility: visible;
}

.activity-date {
    color: #CFD0DC;
    font-size: 10px;
    margin-bottom: 5px;
    margin-left: 20px;
    margin-top: 10px;
}

.wrap-activity-item {
    padding: 10px 20px;
    cursor: pointer;
    position: relative;
    background: #F9FBFC;
}

.wrap-activity-group {
    width: calc(100% - 25px);
    margin-bottom: 10px;
}

.wrap-activity-group .wrap-activity-item {
    border: 1px solid #EFF1F6;
    border-bottom: none;
}

.wrap-activity-group .wrap-activity-item:first-child {
    border-radius: 0px 6px 0px 0px;
}

.wrap-activity-group .wrap-activity-item:last-child {
    border-radius: 0px 0px 6px 0px;
    border-bottom: 1px solid #EFF1F6;
}

.wrap-activity-group .wrap-activity-item:hover {
    width: 100%;
    box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.08);
    background: #fff;
}

.wrap-activity-group .wrap-activity-item:hover .activity-more {
    right: 27px;
}

.activity-icon {
    margin-right: 10px;
}

.activity-info {
    width: 140px;
}

.activity-phone {
    font-size: 13px;
}

.activity-via {
    font-size: 10px;
    color: #B6B6C4;
}

.activity-time {
    font-size: 10px;
    color: #C9C9DA;
}

.activity-more {
    position: absolute;
    right: -15px;
}

.wrap-toolbar-bottom .icon {
    display: none;
}

.wrap-toolbar-bottom.bg-transparent {
    background: transparent !important;
}

.wrap-toolbar-bottom.bg-transparent .icon {
    display: initial !important;
}

.wrap-toolbar-bottom.bg-transparent .icon-gray {
    display: none;
}

#page-incomming-call {
    height: calc(100% - 30px) !important;
}

.drop-down-action {
    position: absolute;
    width: 148px;
    background: #FFFFFF;
    box-shadow: 0px 0px 8px rgba(83, 92, 198, 0.15);
    border-radius: 12px;
    right: 10px;
    top: 38px;
    line-height: 39px;
    font-size: 15px;
    color: #4F4F4F;
    padding: 15px;
}

.drop-down-action ul li {
    display: flex;
}

.drop-down-action ul li img {
    margin-right: 10px;
}

.drop-down-action ul li:hover {
    color: #6154EE;
}

.drop-down-action ul li:hover .icon {
    display: none;
}

.drop-down-action ul li:hover .icon-hover {
    display: initial !important;
}

.drop-down-rectangle {
    position: absolute;
    width: 13px;
    height: 13px;
    background: #FFFFFF;
    transform: rotate(45deg);
    top: -5px;
    right: 10px;
}

.top-bar-title {
    color: #4F4F4F;
    font-size: 12px;
    margin-top: 34px;
}

.mode-minimize {
    width: 206px !important;
    height: 44px !important;
    border-radius: 8px !important;
}

.mode-minimize #app {
    display: none;
}

.mode-minimize #app-minimize {
    display: block;
}

#app-minimize {
    width: 100%;
    height: 100%;
    position: absolute;
    background: #fff;
    display: none;
}

.wrap-info-minimize {
    width: calc(206px - 30px);
    margin-left: 10px;
    margin-top: 9px;
}

.wrap-info-minimize .time {
    padding: 3px 12px;
}

.line-vertical {
    width: 1px;
    border-right: 1px solid #F5F7FF;
}

/*# sourceMappingURL=style.css.map */
.top-bar-status {
    position: absolute;
    font-size: 12px;
    top: 12px;
    left: 20px;
}

.wrap-option-call {
    padding: 20px;
    height: 100%;
    background: #fffffff2;
}

.wrap-option-call .btn-otption-call {
    width: 100%;
    height: 50px;
    font-size: 14px;
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 25px;
    color: #fff;
    border: none;
    position: relative;
}

.wrap-option-call .btn-icon {
    position: absolute;
    left: 25px;
    width: 28px;
    height: 28px;
    top: 11px;
}

.wrap-option-call .btn-icon img {
    margin-top: 7px;
}

.btn-free-voice-call {
    background: linear-gradient(262.92deg, #3fc635 6.49%, #3fb929 108.73%);
    box-shadow: 0px 4px 24px rgba(50, 153, 150, 0.25);
}

.btn-free-video-call {
    background: linear-gradient(262.92deg, #3fc7fd 6.49%, #2ba7fc 108.73%);
    box-shadow: 0px 4px 24px rgba(50, 153, 150, 0.25);
}

.btn-free-callout {
    background: linear-gradient(262.92deg, #b589d3 6.49%, #9a5ac6 108.73%);
    box-shadow: 0px 4px 24px rgba(50, 153, 150, 0.25);
}

.wrap-action-top-right button img {
    vertical-align: middle;
}

.wrapper-dropdown-call {
    float: left;
}

.wrapper-dropdown-call.auto {
    float: right;
}

.wrapper-dropdown-call #dropdown-option-call,
.wrapper-dropdown-call #dropdown-auto-receive-call {
    width: 30px;
    font-size: 12px;
    border: none;
    box-shadow: none;
    padding: 5px;
}

.wrapper-dropdown-call #dropdown-option-call:after,
.wrapper-dropdown-call #dropdown-auto-receive-call:after {
    border-color: #ccc transparent;
    right: 10px;
    border-width: 4px 4px 0 4px;
}

.wrapper-dropdown-call #dropdown-option-call .icon-option-active,
.wrapper-dropdown-call #dropdown-auto-receive-call .icon-option-active {
    width: 14px;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
}

.wrapper-dropdown-call .dropdown {
    left: initial;
    right: -50px;
    width: 250px;
    z-index: 1;
    top: 35px;
    -webkit-transition: all 0.2s ease-in;
    -moz-transition: all 0.2s ease-in;
    -ms-transition: all 0.2s ease-in;
    -o-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in;
}

.wrapper-dropdown-call.auto .dropdown {
    width: 200px;
    right: 0px;
    min-height: 70px;
}

.wrapper-dropdown-call.auto .dropdown:after {
    right: 6px !important;
}

.wrapper-dropdown-call.auto .dropdown:before {
    right: 4px !important;
}

.setting-agent li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    font-size: 14px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.17);
    margin: 4px;
    cursor: pointer;
}

.setting-agent li:last-child {
    border: none;
}

.setting-agent li.disabled {
    background: #f2f7fe;
}

.setting-agent li input {
    width: auto;
}

.setting-agent li label {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.stringee_clound input[type=checkbox] {
    position: relative;
    cursor: pointer;
}

.stringee_clound input[type=checkbox]:before {
    content: "";
    display: block;
    position: absolute;
    width: 12px;
    height: 12px;
    top: 0;
    left: 0;
    border: 1px solid #0000002b;
    border-radius: 3px;
    background-color: white;
}

#autopickcheckbox:disabled:before {
    background-color: #f2f7fe;
}

.stringee_clound input[checked=checked]:after {
    content: "";
    display: block;
    width: 5px;
    height: 9px;
    border: solid #8AA8BD;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 0px;
    left: 4px;
}

.wrapper-dropdown-call .dropdown .icon-option {
    width: 13px;
    vertical-align: middle;
    margin-right: 5px;
}

.wrapper-dropdown-call .dropdown li a {
    padding: 8px 12px;
}

.wrapper-dropdown-call .dropdown:after {
    right: 70px;
}

.wrapper-dropdown-call .dropdown:before {
    right: 68px;
}

.diapad-size-medium .diapad-key {
    height: 48px;
}

#page-calling.diapad-when-calling .wrap-background {
    display: none;
}

#page-calling.diapad-when-calling .wrap-diapad-when-calling {
    display: block !important;
}

.stringee_clound input::selection {
    /* background: #f2f7fe; */
}

.stringee_clound input::-moz-selection {
    /* background: #f2f7fe; */
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.stringee_clound input:checked+.slider {
    background-color: #2196F3;
}

.stringee_clound input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
}

.stringee_clound button:focus {
    outline: none;
    border: none;
}

.stringee_clound input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

.no-recent-call {
    width: 100%;
    text-align: center;
    color: #ff0000;
    font-size: 12px;
    padding-top: 15px;
}
.popover-stringee {
    position: fixed;
    top: 60px;
    right: 10px;
    background: rgb(82, 196, 26);
    border: none;
    z-index: 99;
}

.popover-overlay-stringee .ant-popover-inner {
    border-radius: 15px !important;
}

.popover-overlay-stringee .ant-popover-inner-content {
    padding: 0 !important;
}